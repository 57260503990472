import {
  ButtonImage,
  CasualJacketImage,
  FeltImage,
  JacketImage,
  NameLabelImage,
  OuterwearImage,
  PantImage,
  PipingImage,
  ShirtImage,
  SuedeImage,
  ThreadImage,
  TrouserTrimImage,
  VestImage,
  ZipperImage,
} from '../assets'
import { AWS_URL } from './urls'

export const SHIRT = {
  abbreviation: 'CSHT',
  formalName: 'Shirt',
  alternateName: null,
  bitmask: 8,
  image: ShirtImage,
  fallbackImage: `${AWS_URL}/model_images/ghost_images/shirt.png?1=1`,
} as const

export const COAT = {
  abbreviation: 'CSC',
  formalName: 'Jacket',
  alternateName: null,
  bitmask: 1,
  image: JacketImage,
  fallbackImage: `${AWS_URL}/model_images/ghost_images/jacket.png?1=1`,
} as const

export const VEST = {
  abbreviation: 'CV',
  formalName: 'Vest',
  alternateName: null,
  bitmask: 2,
  image: VestImage,
  fallbackImage: `${AWS_URL}/model_images/ghost_images/vest.png?1=1`,
} as const

export const PANT = {
  abbreviation: 'CT',
  formalName: 'Pant',
  alternateName: null,
  bitmask: 4,
  image: PantImage,
  fallbackImage: `${AWS_URL}/model_images/ghost_images/pant.png?1=1`,
} as const

export const TOPCOAT = {
  abbreviation: 'CTOPC',
  formalName: 'Outerwear',
  alternateName: null,
  bitmask: 32,
  image: OuterwearImage,
  fallbackImage: `${AWS_URL}/model_images/ghost_images/outerwear.png?1=1`,
} as const

export const SHORT = {
  abbreviation: 'CSHO',
  formalName: 'Short',
  alternateName: null,
  bitmask: 64,
  image: null,
  fallbackImage: `${AWS_URL}/model_images/ghost_images/pant.png?1=1`,
} as const

export const SWACKET = {
  abbreviation: 'SWK',
  formalName: 'Swacket',
  alternateName: null,
  bitmask: 512,
  image: null,
  fallbackImage: null,
} as const

export const BREEK = {
  abbreviation: 'CBK',
  formalName: 'Breek',
  alternateName: null,
  bitmask: 128,
  image: null,
  fallbackImage: null,
} as const

export const COAT_PANT = {
  abbreviation: 'CCP',
  formalName: 'Suit',
  alternateName: '2 Piece Suit',
  bitmask: 5,
  image: null,
  fallbackImage: `${AWS_URL}/model_images/ghost_images/suit.png?1=1`,
} as const

export const COAT_PANT_PANT = {
  abbreviation: 'CCPP',
  formalName: 'Suit',
  alternateName: '2 Piece Suit + Pant',
  bitmask: 21,
  image: null,
  fallbackImage: `${AWS_URL}/model_images/ghost_images/suit.png?1=1`,
} as const

export const COAT_VEST_PANT = {
  abbreviation: 'CCVP',
  formalName: 'Suit',
  alternateName: '3 Piece Suit',
  bitmask: 7,
  image: null,
  fallbackImage: `${AWS_URL}/model_images/ghost_images/suit.png?1=1`,
} as const

export const COAT_VEST_PANT_PANT = {
  abbreviation: 'CCVPP',
  formalName: 'Suit',
  alternateName: '3 Piece Suit + Pant',
  bitmask: 23,
  image: null,
  fallbackImage: `${AWS_URL}/model_images/ghost_images/suit.png?1=1`,
} as const

export const CASUALJACKET = {
  abbreviation: 'CASUALJACKET',
  formalName: 'Casual Jacket',
  alternateName: null,
  bitmask: 1024,
  image: CasualJacketImage,
  fallbackImage: `${AWS_URL}/model_images/ghost_images/jacket.png?1=1`,
} as const

export const ALL_GARMENTS = [
  SHIRT,
  COAT,
  VEST,
  PANT,
  COAT_PANT,
  COAT_VEST_PANT,
  COAT_PANT_PANT,
  COAT_VEST_PANT_PANT,
  TOPCOAT,
  SHORT,
  BREEK,
  SWACKET,
  CASUALJACKET,
] as const

//* MATERIALS
export const BUTTONS = { id: 'buttons', name: 'Buttons', image: ButtonImage }
export const FELT = { id: 'felts', name: 'Felt', image: FeltImage }
export const SUEDE = { id: 'microsuedes', name: 'Microsuede', image: SuedeImage }
export const PIPING = { id: 'pipings', name: 'Piping', image: PipingImage }
export const LABELS = { id: 'name-labels', name: 'Name Labels', image: NameLabelImage }
export const THREADS = { id: 'threads', name: 'Threads', image: ThreadImage }
export const TROUSER_TRIM = { id: 'trouser-trims', name: 'Trouser Trim', image: TrouserTrimImage }
export const ZIPPERS = { id: 'zippers', name: 'Zippers', image: ZipperImage }

export const ALL_MATERIALS = [BUTTONS, FELT, SUEDE, PIPING, LABELS, THREADS, TROUSER_TRIM, ZIPPERS]
